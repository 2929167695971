import {
  APP_LOAD,
  LOGIN,
  LOGOUT,
  REGISTER,
  REDIRECT,
  USERS_LOADED,
  CONFIGS_LOADED,
  EMAILS_LOADED,
} from '../constants/actionTypes';

const defaultState = {};

export default (state = defaultState, action) => {
  switch (action.type) {
    case APP_LOAD:
      return {
        ...state,
        token: action.token || null,
        currentUser: action.payload ? action.payload.user : null,
      };
    case REDIRECT:
      return { ...state, redirectTo: null };
    case LOGIN:
    case REGISTER:
      return {
        ...state,
        redirectTo: action.error ? null : '/',
        token: action.error ? null : action.payload.user.token,
        currentUser: action.error ? null : action.payload.user,
        errors: action.error ? action.payload.errors : null,
      };
    case LOGOUT:
      return { ...state, redirectTo: '/', token: null, currentUser: null };
    case USERS_LOADED:
    case CONFIGS_LOADED:
    case EMAILS_LOADED:
      return {
        ...state,
        errors: action.error ? action.payload.errors : null,
      };
    default:
      return state;
  }
};
