import superagentPromise from 'superagent-promise';
import _superagent from 'superagent';
import { API_ROOT } from './constants/paths';

const superagent = superagentPromise(_superagent, global.Promise);
const responseBody = res => res.body;

let token = null;
const tokenPlugin = req => {
  if (token) {
    req.set('authorization', `Token ${token}`);
  }
};

const requests = {
  get: url =>
    superagent
      .get(`${API_ROOT}${url}`)
      .use(tokenPlugin)
      .then(responseBody),
  post: (url, body) =>
    superagent
      .post(`${API_ROOT}${url}`, body)
      .use(tokenPlugin)
      .then(responseBody),
  put: (url, body) =>
    superagent
      .put(`${API_ROOT}${url}`, body)
      .use(tokenPlugin)
      .then(responseBody),
  del: url =>
    superagent
      .del(`${API_ROOT}${url}`)
      .use(tokenPlugin)
      .then(responseBody),
};

const Auth = {
  current: () => requests.get('/user'),
  login: (email, password) =>
    requests.post('/users/login', {
      user: { email, password },
    }),
  register: (email, password) =>
    requests.post('/users', {
      user: { email, password },
    }),
  save: user => requests.put('/user', { user }),
};

const Users = {
  get: () => requests.get(`/users`),
  update: user => requests.put(`/users/${user.id}`, { user }),
  email: (user, msg) => requests.post(`/users/${user.id}/email`, { msg }),
};

const Configs = {
  get: () => requests.get(`/configs`),
  update: config => requests.put(`/configs/${config._id}`, { config }),
};

const Emails = {
  get: () => requests.get(`/emails`),
  update: email => requests.put(`/emails/${email._id}`, { email }),
  add: email => requests.post(`/emails`),
  remove: email => requests.del(`/emails/${email._id}`),
};

export default {
  Users,
  Configs,
  Emails,
  Auth,
  setToken: _token => {
    token = _token;
  },
};
