import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
// import { routerReducer } from 'react-router-redux';

import common from './reducers/common';
import auth from './reducers/auth';
import data from './reducers/data';

export default (history) => combineReducers({
  router: connectRouter(history),
  common,
  auth,
  data,
});
