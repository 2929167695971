import React from 'react';
import { connect } from 'react-redux';
import ReactDataGrid from 'react-data-grid';

import { Editors, Menu } from 'react-data-grid-addons';

import agent from '../agent';
import {
  USERS_LOADED,
  USER_UPDATED,
} from '../constants/actionTypes';

const { DropDownEditor } = Editors;

const { ContextMenu, MenuItem, ContextMenuTrigger } = Menu;

const mapStateToProps = (state, ownProps) => {
  return {
    users: state.data.users,
  };
};

const mapDispatchToProps = dispatch => ({
  onLoad: payload => dispatch({ type: USERS_LOADED, payload }),
  onUpdateUser: (idx, payload) =>
    dispatch({ type: USER_UPDATED, idx, payload }),
});

function RowContextMenu({
  idx,
  id,
  rowIdx,
  onRowUpdateBody,
  onRowEmail,
  onRowInsertAbove,
  onRowInsertBelow,
}) {
  return (
    <ContextMenu id={id}>
      <MenuItem data={{ rowIdx, idx }} onClick={onRowEmail}>
        Email
      </MenuItem>
    </ContextMenu>
  );
}

const BooleanFormatter = ({ value }) => {
  if (typeof(value) === 'boolean') {
    return value.toString();
  } else {
    return '';
  }
};

const booleanTypes = [
  { id: 0, value: true, text: 'true' },
  { id: 1, value: false, text: 'false' },
];

const BooleanEditor = <DropDownEditor options={booleanTypes} />;

const columns = [
  { key: 'id', name: 'id', editable: false },
  { key: 'createdAt', name: 'created', editable: false },
  { key: 'username', name: 'username', editable: false },
  { key: 'email', name: 'email', editable: true },
  { key: 'enabled', name: 'enabled', editable: true, formatter: BooleanFormatter, editor: BooleanEditor },
];

class Users extends React.Component {
  componentDidMount() {
    window.setTimeout(() => {
      this.props.onLoad(agent.Users.get());
    }, 500);
  }

  onGridRowsUpdated = ({ fromRow, toRow, updated }) => {
    if (!updated || updated._id) return;
    const user = this.props.users[fromRow];
    updated.id = user.id;
    this.props.onUpdateUser(fromRow, agent.Users.update(updated));
  };

  onEmail = async idx => {
    const msg = window.prompt('Message:');
    if (!msg) return;
    const user = this.props.users[idx];
    await agent.Users.email(user, msg);
    alert('Email sent.');
  }

  render() {
    const { users } = this.props;

    return (
      <div>
        <ReactDataGrid
          columns={columns}
          rowCount={users.length}
          rowGetter={i => users[i]}
          rowsCount={users.length}
          minHeight={500}
          enableCellSelect={true}
          onGridRowsUpdated={this.onGridRowsUpdated}
          contextMenu={
            <RowContextMenu
              onRowEmail={(e, { rowIdx }) => this.onEmail(rowIdx)}
            />
          }
          RowsContainer={ContextMenuTrigger}
          />   
      </div>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Users);