import React from 'react';
import { connect } from 'react-redux';
import ReactDataGrid from 'react-data-grid';
import { Editors } from 'react-data-grid-addons';

import agent from '../agent';
import {
  CONFIGS_LOADED,
  CONFIG_UPDATED,
} from '../constants/actionTypes';

const { DropDownEditor } = Editors;

const mapStateToProps = (state, ownProps) => {
  return {
    configs: state.data.configs,
  };
};

const mapDispatchToProps = dispatch => ({
  onLoad: payload => dispatch({ type: CONFIGS_LOADED, payload }),
  onUpdateConfig: (idx, payload) =>
    dispatch({ type: CONFIG_UPDATED, idx, payload }),
});

const signupTypes = [
  { id: 0, value: 'OFF', text: 'OFF' },
  { id: 1, value: 'APPROVED_ONLY', text: 'APPROVED_ONLY' },
  { id: 1, value: 'ANY', text: 'ANY' },
];

const SignupEditor = <DropDownEditor options={signupTypes} />;

const booleanTypes = [
  { id: 0, value: true, text: 'true' },
  { id: 1, value: false, text: 'false' },
];

const BooleanEditor = <DropDownEditor options={booleanTypes} />;

const BooleanFormatter = ({ value }) => {
  if (typeof(value) === 'boolean') {
    return value.toString();
  } else {
    return '';
  }
};

const columns = [
  { key: '_id', name: 'id', editable: false },
  { key: 'name', name: 'name', editable: true },
  { key: 'maintainenceMode', name: 'maintainenceMode', editable: true, editor: BooleanEditor, formatter: BooleanFormatter },
  { key: 'signup', name: 'signup', editable: true, editor: SignupEditor },
  { key: 'active', name: 'active', editable: true, editor: BooleanEditor, formatter: BooleanFormatter },
];

class Configs extends React.Component {
  componentDidMount() {
    window.setTimeout(() => {
      this.props.onLoad(agent.Configs.get());
    }, 500);
  }

  onGridRowsUpdated = ({ fromRow, toRow, updated }) => {
    if (!updated || updated._id) return;
    const config = this.props.configs[fromRow];
    updated._id = config._id;
    this.props.onUpdateConfig(fromRow, agent.Configs.update(updated));
  };

  render() {
    const { configs } = this.props;

    return (
      <div>
        <ReactDataGrid
          columns={columns}
          rowCount={configs.length}
          rowGetter={i => configs[i]}
          rowsCount={configs.length}
          minHeight={500}
          enableCellSelect={true}
          onGridRowsUpdated={this.onGridRowsUpdated}
          />     
      </div>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Configs);