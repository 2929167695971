import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from 'react-router-dom';

import { APP_LOAD, LOGOUT, REDIRECT } from '../constants/actionTypes';

import './App.css';

import NavBar from './NavBar';
import ListErrors from './ListErrors';
import Home from './Home';
import Login from './Login';
import Users from './Users';
import Configs from './Configs';
import Emails from './Emails';

import agent from '../agent';

const mapStateToProps = (state, ownProps) => {
  return {
    currentUser: state.common.currentUser,
    redirectTo: state.common.redirectTo,
  };
};

const mapDispatchToProps = dispatch => ({
  onLoad: (payload, token) =>
    dispatch({ type: APP_LOAD, payload, token, skipTracking: true }),
  onLogout: () => dispatch({ type: LOGOUT }),
  onRedirect: () => dispatch({ type: REDIRECT }),
});

function PrivateRoute({ component: Component, authed, ...rest }) {
  return (
    <Route
      {...rest}
      render={props =>
        authed !== null ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{ pathname: '/login', state: { from: props.location } }}
          />
        )
      }
    />
  );
}

function AuthRoute({ component: Component, authed, ...rest }) {
  return (
    <Route
      {...rest}
      render={props =>
        !authed ? (
          <Component {...props} />
        ) : (
          <Redirect to={{ pathname: '/', state: { from: props.location } }} />
        )
      }
    />
  );
}

class App extends Component {
  componentDidMount() {
    const token = window.localStorage.getItem('jwt');
    console.log(token)
    if (token) {
      agent.setToken(token);
    }
    this.props.onLoad(token ? agent.Auth.current() : null, token);
  }

  componentDidUpdate(nextProps) {
    if (nextProps.redirectTo) {
      // store.dispatch(push(nextProps.redirectTo));
      this.props.history.push(nextProps.redirectTo);
      this.props.onRedirect();
    }
  }

  render() {
    const { currentUser } = this.props;
    return (
      <div className="App">
        <Router>
          <NavBar currentUser={currentUser} logout={this.props.onLogout} />
          <section>
            <div>
              <ListErrors />
              <Switch>
                <Route path="/" exact component={Home} />
                <AuthRoute
                  path={`${process.env.PUBLIC_URL}/login`}
                  component={Login}
                  authed={currentUser}
                />
                <PrivateRoute
                  path="/users"
                  component={Users}
                  authed={currentUser}
                />
                <PrivateRoute
                  path="/configs"
                  component={Configs}
                  authed={currentUser}
                />  
                <PrivateRoute
                  path="/emails"
                  component={Emails}
                  authed={currentUser}
                />                                              
              </Switch>
            </div>
          </section>
        </Router>
      </div>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(App);
