import React from 'react';
import { connect } from 'react-redux';

const mapStateToProps = (state, ownProps) => {
  return {
    currentUser: state.common.currentUser,
    redirectTo: state.common.redirectTo,
  };
};

function Home({ currentUser }) {
  return (
    <div style={{textAlign: 'center'}}>
      <h1>Welcome to the Admin</h1>
      {currentUser ? (
        <p>Choose a category from the left menu.</p>
      ) : (
        <p>Press login to begin.</p>
      )}
    </div>
  );
}

export default connect(mapStateToProps)(Home);
