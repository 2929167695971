import React from 'react';
import { connect } from 'react-redux';
import ReactDataGrid from 'react-data-grid';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';

import { Editors, Menu } from 'react-data-grid-addons';

import agent from '../agent';
import {
  EMAILS_LOADED,
  EMAIL_UPDATED,
  EMAIL_ADDED,
  EMAIL_REMOVED,
} from '../constants/actionTypes';

const { DropDownEditor } = Editors;
const { ContextMenu, MenuItem, ContextMenuTrigger } = Menu;

const mapStateToProps = (state, ownProps) => {
  return {
    emails: state.data.emails,
  };
};

const mapDispatchToProps = dispatch => ({
  onLoad: payload => dispatch({ type: EMAILS_LOADED, payload }),
  onUpdateEmail: (idx, payload) =>
    dispatch({ type: EMAIL_UPDATED, idx, payload }),
  onAdd: payload => dispatch({ type: EMAIL_ADDED, payload }),
  onRemove: (idx, payload) => dispatch({ type: EMAIL_REMOVED, payload }),
});

const booleanTypes = [
  { id: 0, value: true, text: 'true' },
  { id: 1, value: false, text: 'false' },
];

const BooleanEditor = <DropDownEditor options={booleanTypes} />;

const BooleanFormatter = ({ value }) => {
  if (typeof(value) === 'boolean') {
    return value.toString();
  } else {
    return '';
  }
};

function RowContextMenu({
  idx,
  id,
  rowIdx,
  onRowUpdateBody,
  onRowDelete,
  onRowInsertAbove,
  onRowInsertBelow,
}) {
  return (
    <ContextMenu id={id}>
      <MenuItem data={{ rowIdx, idx }} onClick={onRowDelete}>
        Delete Row
      </MenuItem>
    </ContextMenu>
  );
}

const columns = [
  { key: '_id', name: 'id', editable: false },
  { key: 'createdAt', name: 'created', editable: false },
  { key: 'address', name: 'address', editable: true },
  { key: 'approved', name: 'approved', editable: true, editor: BooleanEditor, formatter: BooleanFormatter },
];

class Emails extends React.Component {
  componentDidMount() {
    window.setTimeout(() => {
      this.props.onLoad(agent.Emails.get());
    }, 500);
  }

  onGridRowsUpdated = ({ fromRow, toRow, updated }) => {
    if (!updated || updated._id) return;
    const email = this.props.emails[fromRow];
    updated._id = email._id;
    this.props.onUpdateEmail(fromRow, agent.Emails.update(updated));
  }

  onAdd = () => {
    this.props.onAdd(agent.Emails.add())
  }

  onRemove = idx => {
    const confirmed = window.confirm('Are you sure?');
    if (!confirmed) return;
    const email = this.props.emails[idx];
    this.props.onRemove(idx, agent.Emails.remove(email));
  }

  render() {
    const { emails } = this.props;

    return (
      <div>
        <ReactDataGrid
          columns={columns}
          rowCount={emails.length}
          rowGetter={i => emails[i]}
          rowsCount={emails.length}
          minHeight={500}
          enableCellSelect={true}
          onGridRowsUpdated={this.onGridRowsUpdated}
          contextMenu={
            <RowContextMenu
              onRowDelete={(e, { rowIdx }) => this.onRemove(rowIdx)}
            />
          }
          RowsContainer={ContextMenuTrigger}
          />
        <Fab
          color="primary"
          aria-label="Add"
          onClick={this.onAdd}
          style={{ position: 'absolute', bottom: 10, right: 10 }}
        >
          <AddIcon />
        </Fab>        
      </div>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Emails);