export const APP_LOAD = 'APP_LOAD';
export const REDIRECT = 'REDIRECT';
export const LOGIN = 'LOGIN';
export const LOGOUT = 'LOGOUT';
export const REGISTER = 'REGISTER';
export const ASYNC_START = 'ASYNC_START';
export const ASYNC_END = 'ASYNC_END';
export const LOGIN_PAGE_UNLOADED = 'LOGIN_PAGE_UNLOADED';
export const REGISTER_PAGE_UNLOADED = 'LOGIN_PAGE_UNLOADED';
export const UPDATE_FIELD_AUTH = 'UPDATE_FIELD_AUTH';

export const USERS_LOADED = 'USERS_LOADED';
export const USER_UPDATED = 'USER_UPDATED';

export const CONFIGS_LOADED = 'CONFIGS_LOADED';
export const CONFIG_UPDATED = 'CONFIG_UPDATED';

export const EMAILS_LOADED = 'EMAILS_LOADED';
export const EMAIL_UPDATED = 'EMAIL_UPDATED';
export const EMAIL_ADDED = 'EMAIL_ADDED';
export const EMAIL_REMOVED = 'EMAIL_REMOVED';
