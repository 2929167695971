import {
  USERS_LOADED,
  USER_UPDATED,
  CONFIGS_LOADED,
  CONFIG_UPDATED,
  EMAILS_LOADED,
  EMAIL_UPDATED,
  EMAIL_ADDED,
  EMAIL_REMOVED,
} from '../constants/actionTypes';

const initialState = {
  users: [],
  configs: [],
  emails: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case USERS_LOADED:
      return {
        ...state,
        users: action.payload.users,
      };
    case USER_UPDATED: {
      let users = state.users.slice();
      users[action.idx] = action.payload.user;
      return {
        ...state,
        users,
      };
    }
    case CONFIGS_LOADED:
      return {
        ...state,
        configs: action.payload.configs,
      };
    case CONFIG_UPDATED: {
      let configs = state.configs.slice();
      configs[action.idx] = action.payload.config;
      return {
        ...state,
        configs,
      };
    }
    case EMAILS_LOADED:
        return {
          ...state,
          emails: action.payload.emails,
        };
      case EMAIL_UPDATED: {
        let emails = state.emails.slice();
        emails[action.idx] = action.payload.email;
        return {
          ...state,
          emails,
        };
      }
    case EMAIL_ADDED: {
      let emails = state.emails.slice();
      emails.unshift(action.payload.email);
      return {
        ...state,
        emails,
      };
    }      
    case EMAIL_REMOVED: {
      let emails = state.emails.slice();
      emails.splice(action.idx, 1);
      return {
        ...state,
        emails,
      };
    }
    default:
      return state;
  }
};
