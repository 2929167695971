import { createBrowserHistory } from 'history';
import { applyMiddleware, createStore, compose } from 'redux';
import { routerMiddleware } from 'connected-react-router';
import { createLogger } from 'redux-logger';

import createRootReducer from './reducer';

import { promiseMiddleware, localStorageMiddleware } from './middleware';

export const history = createBrowserHistory();

export default function configureStore(preloadedState) {
  const store = createStore(
    createRootReducer(history), // root reducer with router state
    preloadedState,
    compose(
      applyMiddleware(
        routerMiddleware(history), // for dispatching history actions
        // ... other middlewares ...
        promiseMiddleware,
        localStorageMiddleware,
        createLogger(), // TODO add only in dev mode
      ),
    ),
  )

  return store
}
